import { useCallback, useMemo, useRef, useState } from 'react';
import {
  toNumber,
  min,
  head,
  last,
  map,
  union,
  groupBy,
  flatten,
  includes,
  every,
  noop,
} from 'lodash';
import { CorbotoLoading } from '../../../common/loaders';
import { PipelineChangesSvg } from './pipeline-changes.styles';
import {
  IPipelineChangesColumnData,
  IPipelineChangesDataAnnotated,
  MetricAggregateType,
  OnHoverArg,
  StagesWithMetricAggregateType,
} from './pipeline-changes.interfaces';
import {
  useMetricsWithStageAggregation,
  useSegmentStages,
  useAnnotatedData,
  useScaleFeatures,
  pipelineChangesStageOrder,
  useD3DataBuilder,
  useAggregatedMetricsWithTimeLabels,
  NoDataHoc,
  allUpsideStages,
  allDownsideStages,
  useRenderer,
  useDrillLinking,
  useMouseEvents,
  useLegend,
  DEFAULT_PADDING_Y,
  useFocusedData,
  getFocusedLabel,
  useBarColor,
} from './pipeline-changes.hook';
import { useOpenVizLayoutSelector } from '../../../common/redux/selectors/viz-selector.hook';
import { useChartTooltip } from '../chart-tooltip/chart-tooltip.hook';
import { ChartTooltipStyled as ChartTooltip } from '../chart-tooltip/chart-tooltip.styles';
import { IAnyAttribute } from '../../../datasets';
import { DATA_FORMATTER } from '../../../common';

export const PipelineChangesChart = (props: any) => {
  const {
    vizId,
    queryResults,
    vizLoading: loading,
    queryError = false,
    width: propsWidth,
    height: propsHeight,
  } = props;
  const chartWidth = toNumber(propsWidth);
  const chartHeight = toNumber(propsHeight);

  const svgRef = useRef<SVGSVGElement>(null);

  const layout = useOpenVizLayoutSelector({
    discoveryId: vizId,
  });

  // calculate available chart dimensions
  const chartXPadding = min([chartWidth / 10, 75]);
  const [chartYPadding, setYPadding] = useState(DEFAULT_PADDING_Y);

  const metricFields: IAnyAttribute[] = layout?.VALUES ?? [];
  const upsideField = last(layout?.ROWS ?? []);
  const downsideField = head(layout?.COLUMNS ?? []);
  const currentSnapshotField: IAnyAttribute = head(layout?.ROWS ?? []);
  const previousSnapshotField: IAnyAttribute =
    layout?.COLUMNS?.length > 1 ? last(layout?.COLUMNS ?? []) : null;

  const {
    executeQuery: { columnInfo = [], results = [], columnNames = [] } = {},
  } = queryResults;

  const metricsWithStageAggregation: MetricAggregateType[][] = useMetricsWithStageAggregation(
    pipelineChangesStageOrder,
    metricFields,
    columnInfo,
    results,
  );

  const aggregatedMetricsWithTimeLabels: MetricAggregateType[][] = useAggregatedMetricsWithTimeLabels(
    metricsWithStageAggregation,
    columnInfo,
    results,
    currentSnapshotField,
    previousSnapshotField,
  );

  // assume the first metric is meant for y axis
  const yAxisMetrics: MetricAggregateType[] = head(
    aggregatedMetricsWithTimeLabels,
  );

  const stageNames = map(yAxisMetrics, 'stage');
  const stageLabels = map(yAxisMetrics, 'stageLabel');

  const stageNameLabelChangeDict = useMemo(() => {
    const stagesWithMetrics = head(metricsWithStageAggregation);
    return {
      [head(stagesWithMetrics)?.stage]: head(stageNames),
      [last(stagesWithMetrics)?.stage]: last(stageNames),
    };
  }, [metricsWithStageAggregation, stageNames]);

  const upsideStages = union(
    useSegmentStages(
      upsideField?.name ?? '',
      columnNames,
      results,
      stageNameLabelChangeDict,
    ),
    allUpsideStages,
  );

  const downsideStages = union(
    useSegmentStages(
      downsideField?.name ?? '',
      columnNames,
      results,
      stageNameLabelChangeDict,
    ),
    allDownsideStages,
  );

  const metricAggregationsByStage: StagesWithMetricAggregateType = groupBy(
    flatten(aggregatedMetricsWithTimeLabels),
    'stage',
  );

  /**
   * build the data for d3 to consume
   * - calculates absolute and relative amounts for the eventual size/position of bars
   * - label
   * - closed won flag (special color flag)
   * - formatter (for eventual render)
   */
  const pipelineChangesData: IPipelineChangesDataAnnotated[] = useAnnotatedData(
    queryError,
    upsideStages,
    yAxisMetrics,
  );

  // scale-related functions/variables from d3
  const {
    xScale,
    xScaleDomainSelector,
    yScale,
    yTickValues,
    barWidth,
    bandWidth: maximumBarWidth,
  } = useScaleFeatures({
    vizId,
    stageLabels,
    chartXPadding,
    chartYPadding,
    pipelineChangesData,
  });

  const { isDataFocused, setFocusedVizData, buildFocusObject } = useFocusedData(
    {
      vizId,
      toLabel: stageName =>
        getFocusedLabel(stageName, upsideStages, downsideStages, stageNames),
    },
  );

  const { getBarColor } = useBarColor({
    vizId,
    pipelineChangesData,
  });

  const getFieldForDrillLink = useCallback(
    (stageName: string) => {
      const isUpside = includes(upsideStages, stageName);

      return isUpside ? upsideField : downsideField;
    },
    [downsideField, upsideField, upsideStages],
  );

  // data that will be provided to each stage via d3
  const d3Data: IPipelineChangesColumnData[] = useD3DataBuilder({
    vizId,
    xScale,
    xScaleDomainSelector,
    yScale,
    pipelineChangesData,
    metricAggregationsByStage,
    barWidth,
    maximumBarWidth,
    downsideStages,
    isDataFocused,
    getBarColor,
    getFieldByStageName: getFieldForDrillLink,
  });

  useLegend({
    vizId,
    d3Data,
    buildFocusObject,
  });

  const { drillLinkIntoStage } = useDrillLinking({
    vizId,
    getFieldByStageName: getFieldForDrillLink,
    setFocusedVizData,
  });

  // TooltipProvider can eventually live in chart.component.tsx
  const {
    provider: TooltipProvider,
    providerValue,
    setTooltipData = noop,
  } = useChartTooltip({
    vizId,
    svgRef,
  });

  const onHover = useCallback(
    (hoverArgs: OnHoverArg) => {
      const { d3Data, anchor } = hoverArgs || {};
      const hasTooltipData = !!d3Data?.tooltipData;
      hasTooltipData
        ? setTooltipData({
            value: d3Data?.tooltipData,
            anchor,
          })
        : setTooltipData();
    },
    [setTooltipData],
  );

  const {
    onBarMouseEnter,
    onBarMouseMove,
    onBarMouseLeave,
    onBarClick,
  } = useMouseEvents({
    svgRef,
    onHover,
    drillLink: drillLinkIntoStage,
  });

  useRenderer({
    vizId,
    svgRef,
    d3Data,
    xScale,
    yScale,
    yTickValues,
    yTickFormatter:
      head(yAxisMetrics)?.metricValueFormatter ?? DATA_FORMATTER.WHOLE_NUMBER,
    stageLabels,
    onBarMouseEnter,
    onBarMouseMove,
    onBarMouseLeave,
    onBarClick,
    chartYPadding,
    chartXPadding,
    setYPadding,
    upsideStages,
    xScaleDomainSelector,
  });

  const hasNoData =
    !!queryError ||
    results?.length === 0 ||
    stageNames?.length === 0 ||
    ((metricAggregationsByStage as unknown) as any[])?.length === 0 ||
    every(pipelineChangesData, { value: 0 });

  return (
    <>
      {loading && <CorbotoLoading />}
      {!loading && (
        <NoDataHoc condition={hasNoData}>
          <TooltipProvider value={providerValue}>
            <PipelineChangesSvg
              key={'pipeline-chart'}
              id={'vizArea'}
              ref={svgRef}
              width={chartWidth}
              height={chartHeight}
            >
              <ChartTooltip />
            </PipelineChangesSvg>
          </TooltipProvider>
        </NoDataHoc>
      )}
    </>
  );
};
