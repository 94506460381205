import { css, withTheme } from '@emotion/react';
import {
  compose,
  shouldUpdate,
  withProps,
  withPropsOnChange,
  withState,
} from 'react-recompose';
import {
  BaseCartesianChart,
  ShouldEnableReportLinkHOC,
} from '../base-cartesian-chart';
import { connect } from 'react-redux';

import { PureComponent } from 'react';
import { Grid } from 'react-virtualized';
import _, {
  isFunction,
  isObject,
  isEmpty,
  map,
  toPairs,
  get,
  nth,
  filter,
} from 'lodash';
import Utils, { RECORD_COUNT_HEIGHT_DIFF } from './PivotTableUtils';
import Actions from '../../../common/redux/actions/DiscoverActions';
import PivotCell from './PivotCell';
import { BodyRowContext } from './pivot-cell.hook';
import classnames from 'classnames';
import PivotCellRenderers from './PivotCellRenderers';
import { DATA_FORMATTER, hasDashletSuffix } from '../../../common/Constants';
import { scrollbarSize } from '../../../common/utilities/scrollbar-size.util';
import PivotSortFunc from './PivotSortFunc';
import PivotDataFunc from './PivotDataFunc';
import { extent, median } from 'd3';
import NoDataIfHOC from '../NoDataIfHOC';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { createSelector } from 'reselect';
import ScrollDiv from '../../../components/ScrollDiv';
import ReactResizeDetector from 'react-resize-detector';
import CommonUtils from '../../../common/Util';
import { Viz } from '../../VizUtil';
import { messages } from '../../../i18n';
import { withDiscoverRouter } from '../../../common/utilities/router.hoc';

const REPEATS = 1;
const PADDINGS = 28;

/**
 * Renders a tabular or pivot view of data.
 */
class PivotTable extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      scrollLeft: 0,
      scrollTop: 0,
      scrollbarSize: 0,
      hasError: false,
      conditionalFormatting: this.props.getConditionalFormatting(),
      columnWidths: (props.columnSizes ?? [])?.map(_.constant(150)),
    };

    this._leftGridWidth = null;
    this._topGridHeight = null;
    // All Grid refs.
    this.grids = [];
  }

  componentDidMount() {
    this.applyInitialSort();
    // resolve column sizes from char lengths computed earlier
    this.props.measureCells(this.props.columnSizes).then(result => {
      this.setState({ columnWidths: result });
    });
    this.updateLegend();
    this.calculateCachedStyles(null, this.props, null, this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    // if sorting has changed we need to force a recomputation of cell sizes
    if (
      !_.isEqual(this.props.sorting, prevProps.sorting) ||
      !_.isEqual(this.state.columnWidths, prevState.columnWidths) ||
      !_.isEqual(this.props.showRecordCounts, prevProps.showRecordCounts)
    ) {
      this.grids.forEach(g => {
        g.recomputeGridSize();
      });
    }

    if (
      !_.isEqual(
        this.props.conditionalFormatting,
        prevProps.conditionalFormatting,
      )
    ) {
      const conditionalFormatting = this.props.getConditionalFormatting();
      this.setState({ conditionalFormatting });
      this.updateLegend(conditionalFormatting);
    }

    this._leftGridWidth = null;
    this._topGridHeight = null;
    if (!_.isEqual(prevProps.columnSizes, this.props.columnSizes)) {
      prevProps.measureCells(prevProps.columnSizes).then(result => {
        this.setState({ columnWidths: result });
      });
    } else if (
      !_.isEqual(prevProps.showRecordCounts, this.props.showRecordCounts)
    ) {
      // force the everything to re-calculate, pass null as prevProps
      this.calculateCachedStyles(null, this.props, prevState, this.state);
    } else {
      this.calculateCachedStyles(prevProps, this.props, prevState, this.state);
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  updateLegend(legendData = this.state.conditionalFormatting) {
    const legendInfo = legendData.map(f => {
      const formatter =
        this.props.dataFormatters[f.name] || DATA_FORMATTER.NUMBER;
      return {
        label: f.name,
        colorScale: f.colorScale,
        min: f.min,
        max: f.max,
        formatter,
      };
    });
    this.props.setVizLegendData(this.props.vizId, legendInfo);
  }

  applyInitialSort() {
    if (_.isNil(this.props.initialSort)) {
      return;
    }
    const {
      initialSort: {
        direction,
        field: { name },
        from,
      },
      viz,
      colHeaderData,
      sort,
    } = this.props;
    let valueIndex = _.findIndex(viz.layout.VALUES, { name });
    if (valueIndex !== -1) {
      if (from === 'end') {
        valueIndex = 0 - (viz.layout.VALUES.length - valueIndex);
      }

      const parents = Utils.getParentColumns(valueIndex, colHeaderData, viz);
      sort('ROWS', name, direction, parents);
    } else if (_.some(viz.layout.ROWS, { name })) {
      sort('ROWS', name, direction);
    } else if (_.some(viz.layout.COLUMNS, { name })) {
      sort('COLUMNS', name, direction);
    }
  }

  computeGrandTotalRowHeight(props) {
    return props.showRowGrandTotals
      ? props.rowHeight +
          (props.showRecordCounts ? RECORD_COUNT_HEIGHT_DIFF : 0)
      : 0;
  }

  computeBottomScrollHeight(props) {
    let naturalHeight = props.bodyData.length * props.rowHeight;

    if (props.showRecordCounts) {
      const subtotalRows = props.bodyData.filter(
        row => row.type === 'rowSubtotal',
      );
      if (!_.isEmpty(subtotalRows)) {
        naturalHeight += subtotalRows.length * RECORD_COUNT_HEIGHT_DIFF;
      }
    }
    const grandTotalHeight = this.computeGrandTotalRowHeight(props);
    return naturalHeight + grandTotalHeight;
  }

  computeBottomLeftScrollWidth(props) {
    return _.sum(
      _.range(0, Utils.numRows(props)).map(i => props.columnWidths[i] || 0),
    );
  }

  calculateTotalValueCellsWidth() {
    const numRows = Utils.numRows(this.props);
    return _.sum(
      _.range(this.state.columnWidths.length - numRows).map(i =>
        this.calculateTopRightValueWidth(i),
      ),
    );
  }

  computeBottomRightScrollWidth(props) {
    let totalWidth = 0;
    for (let i = 0; i < this.getRightColumnCount(props); i++) {
      const idx = Utils.numRows(props) + i;
      if (idx >= props.columnWidths.length) {
        return 0;
      }
      totalWidth += props.columnWidths[idx] || 0;
    }
    if (Utils.hasColumns(props) && Utils.numValues(props) > 1) {
      totalWidth = this.calculateTotalValueCellsWidth();
    }
    return totalWidth;
  }

  render() {
    const {
      vizId,
      onScroll,
      onSectionRendered,
      scrollingProps,
      i18nPrefs,
      queryResults,
      linkToReport,
      filters,
      openReportLink,
      enableReportLink: isReportLinkEnabled,
      isInSideDrawer,
      ...rest
    } = this.props;

    // rest is passed down, so we deconstruct variables here. Someday, we should not spread props
    const { bodyData, viz } = rest;

    const {
      columnWidths,
      leftScrollLeft,
      rightScrollLeft,
      scrollTop,
    } = this.state;

    const vizType =
      viz.layout.ROWS.length <= 0 ? 'viz-type-flat' : 'viz-type-pivot';
    this.grids = [];

    return (
      <div className={classnames(vizType)} css={css(this._containerOuterStyle)}>
        {Utils.hasRows(this.props) && (
          <div css={css(this._leftContainerStyle)}>
            {this.renderTopLeftGrid({
              ...rest,
              onScroll,
            })}
            <ScrollDiv
              css={css(this._leftContentScrollDivStyle)}
              className={'leftPivotContainer hideScroll'}
              scrollLeft={leftScrollLeft}
              onScroll={this._scrollGridLeftX}
            >
              <Grid
                {...this.props}
                cellRenderer={this.props.topLeftCellRenderer}
                className='pivot-top-left-row-header hideScroll'
                columnCount={Utils.numRows(this.props)}
                columnWidth={({ index }) => {
                  return this.state.columnWidths[index] || 0;
                }}
                onScroll={this._scrollGridLeftX}
                height={Utils.hasRows(this.props) ? this.props.rowHeight : 0}
                rowCount={1}
                css={css(this._topLeftGridStyle)}
                width={this._bottomLeftScrollWidth ?? 0}
                tabIndex={null}
                ref={r => r && this.grids.push(r)}
              />

              {this.renderBottomLeftGrid({
                ...rest,
                columnWidths,
                onScroll,
                scrollTop,
                scrollingProps,
              })}
            </ScrollDiv>
            {this.renderGrandTotalLeft({
              ...rest,
              columnWidths,
              onScroll,
              onSectionRendered,
              scrollingProps,
              i18nPrefs,
            })}

            {Utils.hasRows(this.props) && this._leftIsScrolling && (
              <ScrollDiv
                css={css(this._leftScrollStyle)}
                onScroll={this._onScrollLeftX}
                ref={ref => {
                  this.leftScrollContainer = ref;
                }}
                scrollLeft={leftScrollLeft}
              >
                <div css={css(this._leftScrollbarInnerStyle)} />
              </ScrollDiv>
            )}
          </div>
        )}
        <div
          css={css(this._rightContainerStyle)}
          className={'rightPivotContainer'}
        >
          {this.renderTopRightGrid({
            ...rest,
            columnWidths,
            onScroll,
            scrollLeft: rightScrollLeft,
            scrollingProps,
          })}

          <BodyRowContext.Provider
            value={{
              getCellMeta: (rowIdx, colIdx) => {
                const row = nth(bodyData, rowIdx);
                const rowMeta = get(row, 'rowMeta');

                const colMetaOffset = get(row, 'colMetaOffset', 1);
                const colMeta = get(
                  nth(row?.columnData, colIdx + colMetaOffset),
                  'columnMeta',
                );
                return { cellRowMeta: rowMeta, cellColMeta: colMeta };
              },
              linkToReport,
              isReportLinkEnabled,
              callDrillLink: metaObj => {
                if (
                  !isEmpty(metaObj) &&
                  isObject(metaObj) &&
                  !isEmpty(linkToReport) &&
                  isReportLinkEnabled
                ) {
                  const fieldsInPlay = Viz.getAllFieldsInPlay(viz.layout);
                  const _attrs = filter(
                    map(toPairs(metaObj), ([fieldName, value]) => {
                      const field = fieldsInPlay.find(
                        f => f.name === fieldName,
                      );
                      return {
                        attribute: field,
                        value: [value],
                      };
                    }),
                    'attribute',
                  );

                  const drillContext = {
                    linkToReport,
                    attributes: _attrs,
                    filters,
                  };

                  !isInSideDrawer &&
                    isFunction(openReportLink) &&
                    openReportLink(drillContext);
                }
              },
            }}
          >
            {this.renderBottomRightGrid({
              ...rest,
              columnWidths,
              onScroll,
              onSectionRendered,
              scrollLeft: rightScrollLeft,
              scrollTop,
              scrollingProps,
            })}
          </BodyRowContext.Provider>

          {this.renderGrandTotalRight({
            ...rest,
            columnWidths,
            onScroll,
            onSectionRendered,
            scrollLeft: rightScrollLeft,
            scrollingProps,
            i18nPrefs,
          })}
          {(Utils.hasColumns(this.props) || Utils.hasValues(this.props)) &&
            this._rightIsScrolling && (
              <ScrollDiv
                css={css(this._rightScrollDivStyle)}
                onScroll={this._onScrollRightX}
                ref={ref => {
                  this.leftScrollContainer = ref;
                }}
                scrollLeft={rightScrollLeft}
              >
                <div css={css(this._rightScrollbarInnerStyle)} />
              </ScrollDiv>
            )}
        </div>

        {this._isScrollingVertical && (
          <ScrollDiv
            css={css(this._verticalScrollDivStyle)}
            onScroll={this._onScrollTop}
            ref={ref => {
              this.leftScrollContainer = ref;
            }}
            scrollTop={scrollTop}
          >
            <div css={css(this._verticalScrollbarInnerStyle)} />
          </ScrollDiv>
        )}

        {/* Mobile webkit does not scroll horizontally without this panel. Not needed in desktop */}
        {this.props.isMobile &&
          this._leftIsScrolling &&
          Utils.hasRows(this.props) && (
            <ScrollDiv
              css={css(this._mobileLeftScrollPanel)}
              className={'hideScroll'}
              onScroll={evt => {
                this.onScroll('bothLeft', evt);
              }}
              ref={ref => {
                this.leftScrollContainer = ref;
              }}
              scrollTop={scrollTop}
              scrollLeft={leftScrollLeft}
            >
              <div
                css={css({
                  height: this._bottomScrollHeight ?? 0,
                  width: this._bottomLeftScrollWidth ?? 0,
                  backgroundColor: 'red',
                  opacity: 0.0,
                })}
              />
            </ScrollDiv>
          )}
      </div>
    );
  }

  getBottomGridHeight(props) {
    const { height } = props;

    const topGridHeight = this.getTopGridHeight(props);
    const totalRowHeight = this.getTotalRowHeight(props);

    let maxHeight =
      (!isNaN(height) && _.isNumber(height) ? height : 0) -
      topGridHeight -
      totalRowHeight;

    // Used if there is space enough to show all
    let naturalHeight = props.bodyData.length * props.rowHeight;

    if (props.showRecordCounts) {
      const subtotalRows = props.bodyData.filter(
        row => row.type === 'rowSubtotal',
      );
      if (!_.isEmpty(subtotalRows)) {
        naturalHeight += subtotalRows.length * RECORD_COUNT_HEIGHT_DIFF;
      }
      if (props.showRowGrandTotals) {
        maxHeight -= RECORD_COUNT_HEIGHT_DIFF;
      }
    }
    // Return natural size or max screen area if too large
    return Math.min(naturalHeight, maxHeight);
  }

  getLeftGridWidth(props) {
    if (this._leftGridWidth === null) {
      this._leftGridWidth = this.computeBottomLeftScrollWidth(props);
    }

    let rightNaturalWidth = _.sum(
      props.columnWidths.slice(Utils.numRows(props)),
    );

    // If we're scrolling vertically, add scrollbar size to width
    if (this.scrollingHeight(props)) {
      rightNaturalWidth += scrollbarSize();
    }
    let width;
    if (rightNaturalWidth + this._leftGridWidth < props.width) {
      width = this._leftGridWidth;
    } else if (props.width * 0.4 > rightNaturalWidth) {
      width = props.width - rightNaturalWidth;
    } else {
      width = Math.min(props.width * 0.6, this._leftGridWidth);
    }
    return width;
  }

  /**
   * Get the number of columns on the right side of the pivot
   *
   * @param props
   * @returns {number}
   */
  getRightColumnCount(props) {
    return Utils.isPivotLayout(props)
      ? this.props.dataColumnCount
      : Utils.numColumns(props);
  }

  calculateValueCellsWidthPerGroup(index) {
    const numValues = Utils.numValues(this.props);
    const numRows = Utils.numRows(this.props);
    const groupIdx = Math.floor(index / numValues);
    return _.sum(
      _.range(numValues).map(i => {
        const j = index % numValues ? groupIdx * numValues : index;
        return this.state.columnWidths[numRows + i + j];
      }),
    );
  }

  getTopRightMaxWidth() {
    return Math.max(
      ...this.props.viz.layout.COLUMNS.map(
        ({ name }) =>
          CommonUtils.calcTextWidth(name, PivotDataFunc.pivotFont) +
          PADDINGS +
          2,
      ),
    );
  }

  getMaxTitleWidthPerCol(index) {
    const columnnHeaders = this.props.rawTable.slice(
      0,
      Utils.numColumns(this.props),
    );

    return Math.max(
      ...columnnHeaders.map(
        el =>
          CommonUtils.calcTextWidth(el[index].value, PivotDataFunc.pivotFont) +
          PADDINGS +
          2,
      ),
    );
  }

  calculateTopRightValueWidth(index) {
    const numValues = Utils.numValues(this.props);
    const numRows = Utils.numRows(this.props);
    const columnWidth = this.state.columnWidths[numRows + index];

    if (Utils.hasColumns(this.props)) {
      if (numValues > 1) {
        const maxTitleWidthPerCol = this.getMaxTitleWidthPerCol(index);
        const valueSumWidth = this.calculateValueCellsWidthPerGroup(index);

        if (maxTitleWidthPerCol > valueSumWidth) {
          const delta = (maxTitleWidthPerCol - valueSumWidth) / numValues;
          return columnWidth + delta;
        }
      }

      const topRightMaxWidth = this.getTopRightMaxWidth();
      const naturalWidth = _.sum(this.state.columnWidths.slice(numRows));

      if (topRightMaxWidth > naturalWidth) {
        return topRightMaxWidth / this.props.dataColumnCount;
      }
    }

    return columnWidth || 0;
  }

  getRightGridWidth(props) {
    let { width = 0 } = props;

    if (!Utils.hasColumns(props) && !Utils.hasValues(props)) {
      return 0;
    }

    if (this.scrollingHeight(props)) {
      width -= scrollbarSize();
    }
    const leftGridWidth = this.getLeftGridWidth(props);

    let naturalWidth = _.sum(props.columnWidths.slice(Utils.numRows(props)));

    if (Utils.hasColumns(props) && Utils.numValues(props) > 1) {
      naturalWidth = this.calculateTotalValueCellsWidth();
    }

    const topRightMaxWidth = this.getTopRightMaxWidth();
    if (topRightMaxWidth) {
      naturalWidth =
        topRightMaxWidth > naturalWidth ? topRightMaxWidth : naturalWidth;
    }

    // return natural width if less than screen width
    return Math.min(naturalWidth, width - leftGridWidth);
  }

  getTopGridHeight(props) {
    const { rowHeight } = props;

    if (!this._topGridHeight) {
      if (Utils.isPivotLayout(props)) {
        this._topGridHeight =
          (Utils.numColumns(props) * 2 +
            /* value row */ (Utils.numValues(props) > 0 ? 1 : 0)) *
          rowHeight;
      } else {
        this._topGridHeight = rowHeight;
      }
    }

    return this._topGridHeight;
  }

  getTotalRowHeight(props) {
    const { rowHeight, showRowGrandTotals } = props;
    return props.viz.layout.VALUES.length > 0 && showRowGrandTotals
      ? rowHeight
      : 0;
  }
  /**
   * Avoid recreating inline styles each render; this bypasses Grid's shallowCompare.
   * This method recalculates styles only when specific props change.
   *
   * NOTE: Styles are expected by React-Virtualized. Unable to move these to classes
   */
  calculateCachedStyles(prevProps, props, prevState, state) {
    const { height, rowHeight, width } = props;
    const { columnWidths } = state;

    const measureProps = {
      ...props,
      columnWidths,
    };

    const hardRender =
      !prevProps ||
      !_.isEqual(prevProps.scrollingProps, props.scrollingProps) ||
      !_.isEqual(prevProps.width, props.width) ||
      !_.isEqual(columnWidths, prevState.columnWidths) ||
      prevProps?.bodyData?.length !== props?.bodyData?.length; // scrolling prop change affects styles
    const sizeChange =
      hardRender || height !== prevProps.height || width !== prevProps.width;
    const topSizeChange =
      hardRender ||
      height !== prevProps.height ||
      width !== prevProps.width ||
      rowHeight !== prevProps.rowHeight;

    if (!this._scrollGridLeftX) {
      this._scrollGridLeftX = info => this.onScrollX('left', info);
      this._onScrollLeftX = evt => this.onScroll('left', evt);
      this._onScrollRightX = evt => this.onScroll('right', evt);
      this._onScrollTop = evt => this.onScroll('top', evt);
    }

    if (topSizeChange) {
      this._topGridHeight = this.getTopGridHeight(measureProps);
      this._bottomScrollHeight = this.computeBottomScrollHeight(measureProps);
      this._bottomGridHeight = this.getBottomGridHeight(measureProps);
      this._isScrollingVertical =
        this._bottomScrollHeight > this._bottomGridHeight;
      this._verticalScrollDivStyle = {
        float: 'left',
        position: 'relative',
        top: this._topGridHeight,
        height:
          this._bottomGridHeight +
          this.computeGrandTotalRowHeight(measureProps),
        width: scrollbarSize() ?? 0,
        bottom: '0px',
        overflow: 'hidden auto',
      };
      this._verticalScrollbarInnerStyle = {
        height: this._bottomScrollHeight ?? 0,
        width: 1,
        opacity: 0.0,
      };
      this.forceUpdate();
    }

    if (sizeChange) {
      this._leftGridWidth = this.getLeftGridWidth(measureProps);
      this._rightGridWidth = this.getRightGridWidth(measureProps);
      this._bottomLeftScrollWidth = this.computeBottomLeftScrollWidth(
        measureProps,
      );
      this._bottomRightScrollWidth = this.computeBottomRightScrollWidth(
        measureProps,
      );

      this._leftScrollbarInnerStyle = {
        height: 1,
        width: this._bottomLeftScrollWidth ?? 0,
        backgroundColor: 'red',
        opacity: 0.0,
      };
      this._leftContainerStyle = {
        float: 'left',
        width: `${this._leftGridWidth}px`,
        height: '100%',
      };
      this._leftContentScrollDivStyle = {
        overflowY: 'hidden',
        width: this._leftGridWidth ?? 0,
        height: `${this._bottomGridHeight +
          (Utils.hasRows(props) ? props.rowHeight : 0)}px !important`,
      };
      this._rightContainerStyle = {
        float: 'left',
        height: '100%',
        width: this._rightGridWidth ?? 0,
      };
      this._rightScrollDivStyle = {
        height: scrollbarSize() ?? 0,
        width: this._rightGridWidth ?? 0,
        bottom: '0px',
        overflow: 'auto hidden',
      };
      this._rightScrollbarInnerStyle = {
        height: 1,
        width: this._bottomRightScrollWidth ?? 0,
        opacity: 0.0,
      };

      this._leftIsScrolling = this._leftGridWidth < this._bottomLeftScrollWidth;
      this._rightIsScrolling =
        this._rightGridWidth < this._bottomRightScrollWidth;

      this._mobileLeftScrollPanel = {
        marginTop: this._topGridHeight,
        height:
          this._bottomGridHeight +
          (props.showRowGrandTotals ? props.rowHeight : 0),
        width: this._leftGridWidth ?? 0,
        position: 'absolute',
        zIndex: '200',
        overflow: 'auto',
        pointerEvents: 'none',
        touchAction: 'auto',
      };
      this.forceUpdate();
    }

    if (hardRender || sizeChange) {
      this._containerOuterStyle = {
        height: height ?? 0,
        overflow: 'visible',
        width: this.props.showRecordCounts ? width + 108 : width,
        display: 'flex',
      };
      this._bottomRightGridStyle = {
        overflow: 'auto',
      };
      this._leftScrollStyle = {
        height: scrollbarSize() ?? 0,
        width: this._leftGridWidth ?? 0,
        bottom: '0px',
        zIndex: '200',
        overflow: 'auto hidden',
      };
      this._topRightGridStyle = {
        overflow: 'hidden',
        top: 0,
      };
      this.forceUpdate();
    }

    if (hardRender || sizeChange || topSizeChange) {
      this._containerTopStyle = {
        height: this.getTopGridHeight(measureProps) ?? 0,
        position: 'relative',
        width: width ?? 0,
      };

      this._containerBottomStyle = {
        height: this.getBottomGridHeight(measureProps) ?? 0,
        overflow: 'visible auto',
        position: 'relative',
        width: width ?? 0,
      };
      this.forceUpdate();
    }

    if (hardRender) {
      this._bottomLeftGridStyle = {
        left: 0,
        overflow: 'hidden auto',
        display: 'inline-block',
      };
      this._bottomLeftTotalsGridStyle = {
        left: 0,
        overflow: 'hidden auto',
        display: 'block',
      };
      this._topLeftGridStyle = {
        left: 0,
        overflow: 'auto hidden',
      };
      this.forceUpdate();
    }
  }

  onScroll(side, scrollInfo) {
    let { scrollLeft, scrollTop } = scrollInfo;
    if (_.isUndefined(scrollLeft)) {
      scrollLeft = scrollInfo.currentTarget.scrollLeft;
      scrollTop = scrollInfo.currentTarget.scrollTop;
    }
    // Close overlay triggers by programmatically clicking body
    document.body.click();
    if (side === 'top') {
      scrollTop = scrollInfo.target.scrollTop;
      if (!_.isEqual(this.state.scrollTop, scrollTop)) {
        this.setState({
          ...this.state,
          scrollTop,
        });
      }
    } else if (side === 'bothRight') {
      if (
        !_.isEqual(this.state.rightScrollLeft, scrollLeft) ||
        !_.isEqual(this.state.scrollTop, scrollTop)
      ) {
        this.setState({
          ...this.state,
          scrollTop,
          rightScrollLeft: scrollLeft,
        });
      }
    } else if (side === 'bothLeft') {
      if (
        !_.isEqual(this.state.leftScrollLeft, scrollLeft) ||
        !_.isEqual(this.state.scrollTop, scrollTop)
      ) {
        this.setState({
          ...this.state,
          scrollTop,
          leftScrollLeft: scrollLeft,
        });
      }
    } else {
      // horizontal
      if (!_.isEqual(this.state[`${side}ScrollLeft`], scrollLeft)) {
        this.setState({
          ...this.state,
          [`${side}ScrollLeft`]: scrollLeft,
        });
      }
    }
  }

  onScrollX(side, scrollInfo) {
    let { scrollLeft } = scrollInfo;

    if (_.isUndefined(scrollLeft)) {
      scrollLeft = scrollInfo.currentTarget.scrollLeft;
    }
    if (!_.isEqual(this.state[`${side}ScrollLeft`], scrollLeft)) {
      // Close overlay triggers by programmatically clicking body
      document.body.click();
      this.setState({
        ...this.state,
        [`${side}ScrollLeft`]: scrollLeft,
        isScrolling: true,
      });
    }
  }

  onScrollY(scrollInfo) {
    const { scrollTop } = scrollInfo;
    if (!_.isEqual(this.state.scrollTop, scrollTop)) {
      // Close overlay triggers by programmatically clicking body
      document.body.click();
      this.setState({
        ...this.state,
        scrollTop,
        isScrolling: true,
      });
    }
  }

  /**
   * Adds some special classes if the cell happens to be the first or last
   */
  getPivotCellClasses(col, row, colCount, rowCount) {
    const cl = ['pivot-cell'];
    if (col === 0) {
      cl.push('first-col');
    }
    if (row === 0) {
      cl.push('first-row');
    }
    if (col === colCount - 1) {
      cl.push('last-col');
    }
    if (row === rowCount - 1) {
      cl.push('last-row');
    }
    return cl.join(' ');
  }

  /**
   * Creates a Pivot cell Optionally enabling Sorting and Tooltips
   */
  createCell(
    value,
    key,
    style,
    classes,
    sorting,
    formatter,
    recordCount,
    i18nPrefs = {},
    customFormatter,
  ) {
    return (
      <PivotCell
        {...{
          value,
          key,
          style,
          classes,
          sorting: sorting || {},
          formatter,
          recordCount,
          i18nPrefs,
          customFormatter,
        }}
      />
    );
  }

  scrollingWidth(props) {
    return props.scrollingProps?.horizontal && props.scrollingProps.size;
  }

  scrollingHeight(props) {
    return props.scrollingProps?.vertical && props.scrollingProps.size;
  }

  /**
   * +-----+
   * |x |  |
   * +--+--+
   * |  |  |
   * +--+--+
   */
  renderTopLeftGrid(props) {
    if (Utils.numRows(props) === 0) {
      return [];
    }
    const topLeftHeight =
      (Utils.numColumns(props) +
        (Utils.isPivotLayout(props) && Utils.hasColumns(props)
          ? Utils.numColumns(props) - 1
          : 0) +
        (Utils.hasColumns(props) && Utils.numValues(props) > 0 ? 1 : 0)) *
      this.props.rowHeight;
    return [
      <div
        key='pivot-top-left-spacer'
        className='pivot-top-left-spacer'
        css={css({ ...this._topLeftGridStyle, height: topLeftHeight ?? 0 })}
      />,
    ];
  }

  /**
   * +-----+
   * |  | x|
   * +--+--+
   * |  |  |
   * +--+--+
   */
  renderTopRightGrid(props) {
    const { scrollLeft } = props;

    const topGrids = [];

    if (Utils.isPivotLayout(props)) {
      topGrids.push(
        <Grid
          {...props}
          key={'top-grids-header'}
          cellRenderer={props.topRightCellRenderer}
          className={'pivot-top-right-columns'}
          columnCount={1}
          columnWidth={this.getRightGridWidth(props)}
          height={this.props.rowHeight * Utils.numColumns(props)}
          rowCount={Utils.numColumns(props)}
          css={css(this._topRightGridStyle)}
          tabIndex={null}
          width={this._rightGridWidth ?? 0}
          ref={r => r && this.grids.push(r)}
        />,
      );

      this.props.viz.layout.COLUMNS.forEach((col, idx) => {
        const row = this.props.colHeaderData[idx];
        topGrids.push(
          <Grid
            {...props}
            key={`top-grids-${idx}`}
            cellRenderer={props.topRightColumnsCellRenderer(row, idx)}
            className='pivot-top-right-groups'
            columnCount={row.length}
            columnWidth={({ index }) => {
              // Sum up all previous columns
              const previousColsLength = row
                .slice(0, index)
                .reduce((accum, curr) => accum + curr[1], 0);

              const numRows = Utils.numRows(props);
              const startIdx = numRows + previousColsLength;
              const numValues = Utils.numValues(props);

              const width = _.sum(
                _.range(startIdx, startIdx + row[index][REPEATS]).map(i => {
                  const columnWidth = props.columnWidths[i];
                  const maxTitleWidthPerCol = this.getMaxTitleWidthPerCol(
                    i - numRows,
                  );

                  const groupIdx = Math.floor((i - numRows) / numValues);
                  const valueCellsWidth = _.sum(
                    _.range(numValues).map(
                      k =>
                        props.columnWidths[numRows + k + groupIdx * numValues],
                    ),
                  );

                  if (numValues > 1 && maxTitleWidthPerCol > valueCellsWidth) {
                    return maxTitleWidthPerCol / numValues;
                  }

                  const topRightMaxWidth = this.getTopRightMaxWidth();
                  const naturalWidth = _.sum(props.columnWidths.slice(numRows));

                  if (topRightMaxWidth > naturalWidth) {
                    return topRightMaxWidth / this.props.dataColumnCount;
                  }

                  return columnWidth || 0;
                }),
              );

              return width;
            }}
            height={this.props.rowHeight ?? 0}
            rowCount={1}
            scrollLeft={scrollLeft}
            onScroll={info => this.onScrollX('right', info)}
            css={css({
              ...this._topRightGridStyle,
              overflow: 'auto hidden',
            })}
            tabIndex={null}
            width={this.getRightGridWidth(props) ?? 0}
            ref={r => r && this.grids.push(r)}
          />,
        );
      });
    } else {
      // tabular view. Columns on single header line
      topGrids.push(
        <Grid
          {...props}
          key={'top-grids-cols'}
          cellRenderer={props.topRightTabularCellRenderer}
          className='pivot-top-right-groups'
          columnCount={Utils.numColumns(props)}
          columnWidth={({ index }) => {
            return props.columnWidths[Utils.numRows(props) + index] || 0;
          }}
          height={this.props.rowHeight ?? 0}
          rowCount={1}
          scrollLeft={scrollLeft}
          css={css({
            ...this._topRightGridStyle,
            overflow: 'hidden',
          })}
          tabIndex={null}
          width={this.getRightGridWidth(props) ?? 0}
          ref={r => r && this.grids.push(r)}
        />,
      );
    }

    const colCount = this.getRightColumnCount(props);

    topGrids.push(
      <Grid
        {...props}
        key={`top-grids-values`}
        cellRenderer={props.topRightValuesCellRenderer(colCount)}
        className='pivot-top-right-values'
        columnCount={colCount + (this.scrollingHeight(props) ? 1 : 0)}
        columnWidth={({ index }) => this.calculateTopRightValueWidth(index)}
        height={Utils.hasValues(props) ? this.props.rowHeight : 0}
        rowCount={Utils.numValues(props) > 0 ? 1 : 0}
        scrollLeft={scrollLeft}
        css={css({
          ...this._topRightGridStyle,
          overflow: 'hidden',
        })}
        tabIndex={null}
        width={this.getRightGridWidth(props, this.state) ?? 0}
        ref={r => r && this.grids.push(r)}
      />,
    );
    return topGrids;
  }
  /**
   * +-----+
   * |  |  |
   * +--+--+
   * |x |  |
   * +--+--+
   */
  renderBottomLeftGrid(props) {
    const grids = [];

    const subTotalRowIndexes = this.props.bodyData.reduce(
      (accum, row, index) => {
        if (row.type === 'rowSubtotal') {
          accum.push(index);
        }
        return accum;
      },
      [],
    );

    this.props.viz.layout.ROWS.forEach((col, idx, arr) => {
      const columnCount = Utils.numRows(props);
      const dataRow = props.rowHeaderData[idx] || [];
      const rowCount = dataRow.length;
      const adjustedRowCount = rowCount;
      const colWidth = props.columnWidths[idx];
      // determine if previous row subtotals are on - used for displaying row subtotal titles
      const prevRowSubtotalsOn =
        props.showRowSubtotals &&
        (props.rowSubtotalsFields === 'ALL' ||
          (idx > 0 && _.includes(props.rowSubtotalsFields, arr[idx - 1].name)));
      const { showRecordCounts } = props;
      grids.push(
        <Grid
          {...props}
          key={`bottom-left-grids-${idx}`}
          cellRenderer={props.bottomLeftCellRenderer(
            idx,
            columnCount,
            rowCount,
            dataRow,
            prevRowSubtotalsOn,
          )}
          rowHeight={({ index }) => {
            let height = dataRow[index][REPEATS] * this.props.rowHeight;

            if (showRecordCounts) {
              // how many subtotal rows do we see in this set of rows?
              const startingIndex = dataRow
                .slice(0, index)
                .reduce((accum, rowHeader) => {
                  return accum + rowHeader[REPEATS];
                }, 0);
              const endingIndex = startingIndex + dataRow[index][REPEATS];

              const relatedSubtotalRowIndexes = subTotalRowIndexes.filter(
                subTotalRowIndex =>
                  startingIndex <= subTotalRowIndex &&
                  endingIndex > subTotalRowIndex,
              );

              // we need to increase the height to account for record count data
              height +=
                relatedSubtotalRowIndexes.length * RECORD_COUNT_HEIGHT_DIFF;
            }
            return height;
          }}
          className={'pivot-bottom-left'}
          columnWidth={colWidth}
          columnCount={1}
          height={this._bottomGridHeight ?? 0}
          rowCount={adjustedRowCount}
          onScroll={info => this.onScrollY(info)}
          css={css({ ...this._bottomLeftGridStyle })}
          width={colWidth ?? 0}
          ref={r => r && this.grids.push(r)}
        />,
      );
    });

    return grids;
  }

  /**
   * +-----+
   * |  |  |
   * +--+--+
   * |  | x|
   * +--+--+
   */
  renderBottomRightGrid(props) {
    const columnCount = this.getRightColumnCount(props);
    const rowCount = this.props.bodyData.length;
    const adjustedRowCount = rowCount;

    const { conditionalFormatting } = this.state;
    return (
      <Grid
        {...props}
        cellRenderer={props.bottomRightCellRenderer(
          columnCount,
          rowCount,
          conditionalFormatting,
          props.showRowSubtotals,
          this.props.theme,
        )}
        className={'pivot-bottom-right hideScroll'}
        columnCount={columnCount}
        columnWidth={({ index }) => {
          if (Utils.hasColumns({ viz: this.props.viz })) {
            return this.calculateTopRightValueWidth(index);
          }
          const idx = Utils.numRows(props) + index;
          if (idx >= props.columnWidths.length) {
            return 0;
          }
          return props.columnWidths[idx] || 0;
        }}
        height={this._bottomGridHeight ?? 0}
        onScroll={info => this.onScroll('bothRight', info)}
        rowCount={adjustedRowCount}
        rowHeight={({ index }) => {
          let height =
            index < rowCount ? this.props.rowHeight : scrollbarSize();
          if (
            props.showRecordCounts &&
            this.props.bodyData[index].type === 'rowSubtotal'
          ) {
            height += RECORD_COUNT_HEIGHT_DIFF;
          }
          return height;
        }}
        css={css(this._bottomRightGridStyle)}
        width={this.getRightGridWidth(props) ?? 0}
        onScrollbarPresenceChange={_props => {
          // Re-render based on new scrolling info
          if (rowCount > 0) {
            this.props.setScrollingProps(_props);
          }
        }}
        ref={r => r && this.grids.push(r)}
      />
    );
  }

  /**
   * +-----+
   * |  |  |
   * +--+--+
   * |  |  |
   * +--+--+
   * |x |  |
   */
  renderGrandTotalLeft(props) {
    if (!props.showRowGrandTotals || _.isEmpty(props.rowGrandTotals)) {
      return null;
    }
    let height = this.getTotalRowHeight(props);
    if (props.showRecordCounts) {
      height += RECORD_COUNT_HEIGHT_DIFF;
    }
    const width = this.getLeftGridWidth(props) ?? 0;
    return (
      <Grid
        {...props}
        cellRenderer={({ key, style }) => {
          const value = messages.pivot.grandTotal;
          let recordCount;
          const classes = ['pivot-cell'];
          if (props.showRecordCounts) {
            recordCount = props.rowGrandTotals[0].count;
            if (!_.isNil(recordCount)) {
              classes.push('with-record-count');
            }
          }
          return this.createCell(
            { value },
            key,
            style,
            classes.join(' '),
            undefined,
            undefined,
            recordCount,
            props.i18nPrefs,
          );
        }}
        className={'pivot-total-left'}
        columnCount={1}
        columnWidth={width ?? 0}
        height={height ?? 0}
        onScroll={info => this.onScroll('left', info)}
        rowCount={1}
        rowHeight={() => {
          if (props.showRecordCounts) {
            return this.props.rowHeight + RECORD_COUNT_HEIGHT_DIFF;
          }
          return this.props.rowHeight;
        }}
        css={css({ ...this._bottomLeftTotalsGridStyle })}
        width={width ?? 0}
        ref={r => r && this.grids.push(r)}
      />
    );
  }

  /**
   * +-----+
   * |  |  |
   * +--+--+
   * |  |  |
   * +--+--+
   * |  | x|
   */
  renderGrandTotalRight(props) {
    if (!props.showRowGrandTotals) {
      return null;
    }
    if (_.isEmpty(props.rowGrandTotals[0]?.columnData)) {
      console.warn('Grand totals are ON but there is no data to render');
      return null;
    }

    const summaryRow = props.rowGrandTotals[0]?.columnData;
    const columnOffset = Utils.isPivotLayout(props)
      ? summaryRow.length - (props.dataColumnCount + 1) /* agg column */
      : 0;
    const columnCount = props.dataColumnCount;
    let height = this.getTotalRowHeight(props);
    if (props.showRecordCounts) {
      height += RECORD_COUNT_HEIGHT_DIFF;
    }

    return (
      <Grid
        {...props}
        cellRenderer={({ columnIndex, key, rowIndex, style }) => {
          const { value } = summaryRow[columnIndex + columnOffset];
          const formatter = props.formatters.body[columnIndex];
          const customFormatter = props.formatters.custom[columnIndex];
          const hide = props.hideGrandTotalsForColumnIndexes[columnIndex];
          if (!hide) {
            return this.createCell(
              { value },
              key,
              style,
              Utils.getPivotCellClasses(columnIndex, rowIndex, columnCount) +
                (Utils.hasRows(props) ? ' rows-present' : ''),
              undefined,
              formatter,
              undefined,
              props.i18nPrefs,
              customFormatter,
            );
          } else {
            // this is a column that does not have an aggregation. so don't show one
            return [];
          }
        }}
        className={'pivot-total-right'}
        columnCount={columnCount}
        columnWidth={({ index }) => this.calculateTopRightValueWidth(index)}
        height={height ?? 0}
        rowCount={1}
        rowHeight={() => {
          if (props.showRecordCounts) {
            return this.props.rowHeight + RECORD_COUNT_HEIGHT_DIFF;
          }
          return this.props.rowHeight;
        }}
        css={css({
          ...this._bottomRightGridStyle,
          overflow: 'hidden !important',
          top: 0,
          width: this.getRightGridWidth(props) ?? 0,
        })}
        width={this.getRightGridWidth(props) ?? 0}
        ref={r => r && this.grids.push(r)}
      />
    );
  }
}

const DEFAULT_SORT = {
  ROWS: [],
  COLUMNS: [],
  highlight: {},
};
const showRowGrandTotals = createSelector(
  [VIZ_SELECTORS.getActiveVizLayout, VIZ_SELECTORS.getCustomFormatToggles],
  (layout, customToggles) => {
    const noValuesOrRows =
      layout.VALUES.length === 0 || layout.ROWS.length === 0;
    const hasRowGrandTotalsOn = customToggles.find(
      toggle => toggle.key === 'rowGrandTotals',
    );
    return (
      (_.isNil(hasRowGrandTotalsOn) ? false : hasRowGrandTotalsOn.on) &&
      !noValuesOrRows
    );
  },
);
const showColGrandTotals = createSelector(
  [VIZ_SELECTORS.getActiveVizLayout, VIZ_SELECTORS.getCustomFormatToggles],
  (layout, customToggles) => {
    const noValuesOrCols =
      layout.VALUES.length === 0 || layout.COLUMNS.length === 0;
    const hasColGrandTotalsOn = customToggles.find(
      toggle => toggle.key === 'colGrandTotals',
    );
    return (
      (_.isNil(hasColGrandTotalsOn) ? false : hasColGrandTotalsOn.on) &&
      !noValuesOrCols
    );
  },
);
const showRowSubtotals = createSelector(
  [VIZ_SELECTORS.getActiveVizLayout, VIZ_SELECTORS.getCustomFormatToggles],
  (layout, customToggles) => {
    const noValuesOrRows =
      layout.VALUES.length === 0 || layout.ROWS.length === 0;
    const hasRowSubtotalsOn = customToggles.find(
      toggle => toggle.key === 'rowSubtotals',
    );
    return (
      (_.isNil(hasRowSubtotalsOn) ? false : hasRowSubtotalsOn.on) &&
      !noValuesOrRows
    );
  },
);
const showRecordCounts = createSelector(
  [VIZ_SELECTORS.getActiveVizLayout, VIZ_SELECTORS.getCustomFormatToggles],
  (layout, customToggles) => {
    const noValuesOrRows =
      layout.VALUES.length === 0 || layout.ROWS.length === 0;
    const hasRecordCountsOn = customToggles.find(
      toggle => toggle.key === 'recordCounts',
    );
    return (
      (_.isNil(hasRecordCountsOn) ? false : hasRecordCountsOn.on) &&
      !noValuesOrRows
    );
  },
);

export default compose(
  withDiscoverRouter,
  ShouldEnableReportLinkHOC,
  connect(
    BaseCartesianChart.mapStateToProps, // provides linkToReport
    BaseCartesianChart.mapDispatchToProps, // provides openReportLink
  ),
  // Map sorting to top-level prop, creating default if null. Note when rendering a saved viz options are serialized
  connect(
    (state, props) => {
      const {
        dashlet: { isDashletMode = false } = {},
        main: { isMobile } = {},
      } = state;
      const isInSideDrawer = hasDashletSuffix(props.vizId);
      const open = state.discover.openDiscoveries[props.vizId].present;
      let initialSort = null;
      let existingSorting = _.get(open.viz.options, 'sort', DEFAULT_SORT);
      if (_.isString(existingSorting)) {
        existingSorting = JSON.parse(existingSorting);
        open.viz.options.sort = existingSorting;
      }
      // support for applying sort with limited info from props
      if (existingSorting.direction && existingSorting.field) {
        initialSort = existingSorting;
        existingSorting = DEFAULT_SORT;
      }
      const customFormatToggles = JSON.parse(
        _.get(open, 'viz.options.customFormatToggles', '[]'),
      );
      const rowSubtotalsToggle = _.find(customFormatToggles, {
        key: 'rowSubtotals',
      });

      const getRowSubtotalFields = () => {
        const fields = _.get(rowSubtotalsToggle, 'options.fields', []);
        const rowsFields = _.get(open, 'viz.layout.ROWS', []);

        if (_.isArray(fields)) {
          // filter the fields to only ones in the ROWS shelf
          const filtered = fields.filter(f => {
            return !_.isNil(rowsFields.find(rf => rf.name === f));
          });

          return !_.isEmpty(filtered)
            ? filtered
            : open.viz.layout.ROWS.length > 1
            ? [open.viz.layout.ROWS[0].name]
            : 'ALL';
        } else {
          return fields;
        }
      };

      const filters = Viz.getFiltersFromViz(open.viz);

      return {
        initialSort,
        sorting: Utils.upgradeSortingProps(existingSorting),
        conditionalFormatting: JSON.parse(
          _.get(open, 'viz.options.conditionalFormatting', '[]'),
        ),
        advancedMode: state.main.advanced,
        showRowGrandTotals: showRowGrandTotals(state, props),
        showColGrandTotals: showColGrandTotals(state, props),
        showRowSubtotals: showRowSubtotals(state, props),
        rowSubtotalFields: getRowSubtotalFields(),
        showRecordCounts: showRecordCounts(state, props),
        isDashletMode,
        isMobile,
        filters,
        isInSideDrawer,
      };
    },
    (dispatch, ownProps) => {
      return {
        setSorting(sorting) {
          dispatch(Actions.setPivotSorting(ownProps.vizId, sorting));
        },
      };
    },
  ),
  withPropsOnChange(
    ['queryResults', 'sorting', 'showRecordCounts'],
    PivotDataFunc,
  ),
  // Only create Pivot data if results or sorting has changed
  NoDataIfHOC(props => _.isEmpty(props.originalData)),
  shouldUpdate((curr, next) => {
    const keys = [
      'queryResults',
      'sorting',
      'viz',
      'advancedMode',
      'scrollLeft',
      'scrollTop',
      'i18nPrefs',
      'enableReportLink',
      'linkToReport',
      'i18nPrefs',
    ];
    for (let i = 0; i < keys.length; i++) {
      if (!_.isEqual(curr[keys[i]], next[keys[i]])) {
        return true;
      }
    }
    return false;
  }),
  withProps(PivotSortFunc),
  withProps(props => {
    // Get scale data for pivot values
    const metricScaleData = props.viz.layout.VALUES.map(metric => {
      const metricValues = props.originalData
        .map(d => d[metric.name])
        .filter(d => d !== '-');
      const ext = extent(metricValues);
      metric = {
        name: metric.name,
        min: ext[0],
        median: median(metricValues),
        max: ext[1],
      };
      return metric;
    });

    return {
      getConditionalFormatting: () => {
        const { conditionalFormatting } = props;
        if (_.isEmpty(conditionalFormatting)) {
          return [];
        }
        const data = [];
        conditionalFormatting.forEach(metric => {
          // get metric scale data
          const scaleData = _.find(metricScaleData, _.pick(metric, 'name'));
          if (!_.isNil(scaleData)) {
            const d = { ...metric, ...scaleData };
            // get d3 color scales
            d.d3Scales = Utils.getColorScale(d);
            data.push(d);
          }
        });
        return data;
      },
    };
  }),
  PivotCellRenderers,
  withState('scrollingProps', 'setScrollingProps', {}),
  withTheme,
  Comp => props => {
    const colWidth = props.isMobile ? 110 : 175;
    return (
      <ReactResizeDetector handleWidth handleHeight>
        {({ width = 0, height = 0 }) => {
          return (
            <div className='pivot-table-wrapper'>
              <Comp
                {...props}
                height={(height ?? props.height ?? 32) - 32}
                width={(width ?? props.width ?? 32) - 32}
                columnWidth={colWidth}
                rowHeight={32}
              />{' '}
            </div>
          );
        }}
      </ReactResizeDetector>
    );
  },
)(PivotTable);
