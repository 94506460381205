import { Component } from 'react';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import * as _ from 'lodash';
import { HANDLE_NULL_AS, NULL_DISPLAY, NULL_TOKEN } from '../Constants';
import classnames from 'classnames';
import * as shortId from 'shortid';

const GROW_CIRCLE_RADIUS = 2;

class Circles extends Component {
  constructor(props) {
    super(props);

    const circles = this.computeCircleData(this.props.data);

    this.state = {
      circles,
      circleGroupClass: `g_circle_${shortId.generate()}`,
    };
  }

  componentDidMount() {
    // mechanism to update focusedDataPoints
    if (
      _.isFunction(this.props.onFocusedDataPointUpdate) &&
      this.props.selectionMode
    ) {
      const updatingData = _.find(this.props.data ?? [], _data => {
        const { centerX, centerY } = this.getCirclePosition(_data);
        return (
          (_.isNumber(centerX) || _.isNumber(centerY)) &&
          _.some(this.props.focusedDataPoints ?? [], _dataPoint => {
            if (this.props.isDashletMode) {
              return (
                _.isEqual(this.props.data?.pathInfo, _dataPoint?.pathInfo) &&
                (_.isEqual(_data.x, _dataPoint.xAxis) ||
                  _.isEqual(_data.y, _dataPoint.yAxis)) &&
                (Math.floor(centerX) !== Math.floor(_dataPoint.posX) ||
                  Math.floor(centerY) !== Math.floor(_dataPoint.posY))
              );
            }

            return (
              _.isEqual(this.props.data?.pathInfo, _dataPoint?.pathInfo) &&
              _.isEqual(_data.x, _dataPoint.xAxis) &&
              Math.floor(centerX) !== Math.floor(_dataPoint.posX)
            );
          })
        );
      });

      if (!_.isNil(updatingData)) {
        const updatedDataPoints = _.map(
          this.props.focusedDataPoints,
          _dataPoint => {
            const {
              centerX: updatedXPosition,
              centerY: updatedYPosition,
            } = this.getCirclePosition(_dataPoint);

            return {
              ..._dataPoint,
              posX: updatedXPosition,
              posY: this.props.isDashletMode
                ? updatedYPosition
                : _dataPoint.posY,
            };
          },
        );
        this.props.onFocusedDataPointUpdate(updatedDataPoints);
      }
    }

    if (this.props.isInSideDrawer && !_.isEmpty(this.props.focusedData)) {
      const { centerX: _posX, centerY: _posY } = this.getCirclePosition(
        this.props.focusedData,
      );
      this.props.onClick(this.props.data, {
        ...this.props.focusedData,
        posX: _posX,
        posY: _posY,
      });
    }

    this.setupListeners();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currentCircleData = this.computeCircleData(this.props.data);
    const nextCircleData = this.computeCircleData(nextProps.data);

    const stateChanged = !_.isEqual(this.state, nextState);
    const computedCirclesChanged = !_.isEqual(
      currentCircleData,
      nextCircleData,
    );
    const circlesDataChanged = !_.isEqual(
      this.props.data ?? [],
      nextProps.data ?? [],
    );
    return circlesDataChanged || computedCirclesChanged || stateChanged;
  }

  componentDidUpdate() {
    // change detection currently doesn't happen because of constant unmount/remount from ancestor component
    const currentCircleData = this.computeCircleData(this.props.data);

    if (!_.isEqual(this.state.circles, currentCircleData)) {
      this.setState(
        {
          circles: currentCircleData,
        },
        () => this.setupListeners(),
      );
    }
  }

  selectionModeCircleUpdate(circleIndex, callback) {
    if (!this.props.useSelectionMode) {
      return;
    }
    const circleData = this.state.circles[circleIndex];
    const inFocusedDataSubset =
      _.isEmpty(this.props.focusedData) ||
      _.find(this.props.focusedData, _focusedData => {
        return _.isEqual(circleData.pathInfo, _focusedData);
      });
    const shouldAlterRadius =
      !circleData.isFocusedPoint &&
      inFocusedDataSubset &&
      (_.isEmpty(this.props.focusedDataPoints) ||
        _.some(this.props.focusedDataPoints ?? [], {
          xAxis: circleData.xAxis,
        }));
    if (!_.isNil(circleData) && shouldAlterRadius) {
      callback(circleData);
    }
  }

  setupListeners() {
    d3.select(this.circles)
      .selectAll('.data_point_container')
      .data(_.filter(this.props.data, (item, idx) => _.isNumber(idx)))
      .on('mousemove', () => {
        const lineData = this.props.data;
        if (_.isFunction(this.props.onMouseMove) && !this.props.selectionMode) {
          currentEvent.stopPropagation();
          this.props.onMouseMove(lineData);
        }
      })
      .on('mouseout', (_item, index) => {
        currentEvent.stopPropagation();
        this.selectionModeCircleUpdate(index, circleData => {
          const { radius } = circleData;
          d3.select(this.circles)
            .select(`.g_circle_idx_${index}`)
            .selectAll('circle')
            .attr('r', Math.max(0, radius))
            .classed('hovered', false);
        });
      })
      .on('mouseover', (_item, index) => {
        currentEvent.stopPropagation();
        this.selectionModeCircleUpdate(index, circleData => {
          const { radius } = circleData;
          d3.select(this.circles)
            .select(`.g_circle_idx_${index}`)
            .selectAll('circle')
            .attr('r', Math.max(0, radius + GROW_CIRCLE_RADIUS))
            .classed('hovered', true);
        });
      })
      .on('click', (_item, index) => {
        // d3 gets 'incorrect' data especially when there are un-rendered data points on the line
        // because of that, we should never use _item provided here by d3.
        const circleData = this.state.circles[index];

        const { centerX, centerY, xAxis, pathInfo } = circleData;

        const inFocusedDataSubset =
          _.isEmpty(this.props.focusedData) ||
          _.find(this.props.focusedData, _focusedData => {
            return _.isEqual(circleData.pathInfo, _focusedData);
          });

        if (inFocusedDataSubset && _.isFunction(this.props.onClick)) {
          currentEvent.stopPropagation();
          this.props.onClick(this.props.data, {
            ...circleData,
            posX: centerX,
            posY: centerY,
            xAxis,
            pathInfo,
          });
        }
      });
  }

  computeCircleData(lineData) {
    return _.map(lineData ?? [], (d, idx) => {
      const key = this.props.getId
        ? this.props.getId(d)
        : `[${idx}, ${d.x}, ${d.y}]`;

      const fill = this.props.color ? { fill: this.props.color } : {};

      // strict equality on an object is discouraged
      const focused = this.props.focusedPoint === d ? 'focused' : '';
      const isFocusedPoint = _.some(
        this.props.focusedDataPoints ?? [],
        _focusedDataPoint => {
          const isXValue = _focusedDataPoint.xAxis === d?.x;
          const isOnLine = _.isEqual(
            _focusedDataPoint.pathInfo,
            lineData?.pathInfo,
          );
          return isXValue && isOnLine;
        },
      );

      const inFocusedDataSubset =
        _.isEmpty(this.props.focusedData) ||
        _.find(this.props.focusedData, _focusedData => {
          return _.isEqual(lineData.pathInfo, _focusedData);
        });

      const radius =
        parseInt(this.props.radius) +
        (inFocusedDataSubset && isFocusedPoint ? GROW_CIRCLE_RADIUS : 0);

      const { centerX, centerY } = this.getCirclePosition(d);

      if (_.isNil(centerX) || _.isNil(centerY)) {
        return null;
      }

      const circleData = {
        key,
        fill,
        focused,
        isFocusedPoint,
        radius,
        centerX,
        centerY,
        xAxis: d.x,
        pathInfo: lineData?.pathInfo,
        axises: d.axises,
      };

      return circleData;
    }).filter(Boolean);
  }

  getCirclePosition(circleData) {
    const yIsNull = this.isNull(circleData);
    if (yIsNull && this.props.nullHandling === HANDLE_NULL_AS.MISSING) {
      return [];
    }

    let centerY = this.props.yScale(this.props.getY(circleData));
    if (yIsNull && this.props.nullHandling === HANDLE_NULL_AS.ZERO) {
      centerY = this.props.yScale(0);
    }

    const centerX =
      this.props.xScale(this.props.getX(circleData)) + this.props.offsetX;

    return { centerX, centerY };
  }

  isNull(circleData) {
    const { getY } = this.props;
    const yVal = getY(circleData);
    return (
      _.isNil(circleData) ||
      _.isNil(yVal) ||
      yVal === NULL_DISPLAY ||
      yVal === NULL_TOKEN
    );
  }

  render() {
    return (
      <g
        ref={cir => (this.circles = cir)}
        width={this.props.width}
        height={this.props.height}
        className={this.props.dataKey}
      >
        {_.map(this.state.circles ?? [], (_circleData, idx) => {
          const {
            key,
            fill,
            focused,
            isFocusedPoint,
            radius,
            centerX,
            centerY,
          } = _circleData;

          const isDefined = _.every(
            [centerX, centerY],
            val => !(_.isNil(val) || _.isNaN(val)),
          );

          return (
            <g
              key={`g_circle_${key}`}
              className={`data_point_container g_circle_idx_${idx} ${this.state.circleGroupClass}`}
              style={this.props.style}
            >
              {isDefined && (
                <circle
                  key={`circle_${key}`}
                  className={classnames(this.props.className, {
                    dim: !this.props.focus,
                    bold: isFocusedPoint,
                    outer: true,
                  })}
                  cx={centerX}
                  cy={centerY}
                  r={Math.max(0, radius)}
                  {...fill}
                />
              )}
              {/* the classname should not access props className */}
              {isDefined && (
                <circle
                  key={`circle_${key}_inner`}
                  className={classnames(
                    `${this.props.className}-inner-border`,
                    focused,
                    {
                      dim: !this.props.focus,
                      bold: isFocusedPoint,
                      inner: true,
                    },
                  )}
                  cx={centerX}
                  cy={centerY}
                  r={Math.max(0, radius - 1)}
                />
              )}
            </g>
          );
        })}
      </g>
    );
  }
}

Circles.defaultProps = {
  radius: 3,
  className: 'valCircle',
  focus: true,
  offsetX: 0, // Bar-Line combo adjustment, move points to center of bars
  dataKey: '',
};

export default Circles;
