import {
  isAdmin,
  isAppAdmin,
} from '../common/redux/selectors/AccountSelectors';
import { useIsAdvancedMode } from '../common/redux/selectors/main-selector-hooks';

export const DatasetHOC = HOCComponent => props => {
  const { currentUser } = props;
  const isUserAdmin = isAdmin({ currentUser });
  const isUserAppAdmin = isAppAdmin({ currentUser });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isAdvanced = useIsAdvancedMode();

  return (
    <HOCComponent
      {...props}
      isUserAdmin={isUserAdmin}
      isUserAppAdmin={isUserAppAdmin}
      allowDatasetCreate={isUserAppAdmin && isAdvanced}
    />
  );
};
