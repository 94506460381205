import _, { isEmpty } from 'lodash';

export const doesPointDataHaveMissingValue = ({ focusedData, pointData }) => {
  if (isEmpty(pointData)) {
    return false;
  }

  const focusedDataLines = focusedData.map(({ lines }) => lines[0]);

  return pointData
    .filter(
      ({ pathInfo: { lines } }) =>
        isEmpty(focusedData) || focusedDataLines.includes(lines[0]),
    )
    .every(({ y }) => y === '-' || y === 0);
};

export const selectFDProps = _focusedDataPoints =>
  _.join(
    _.flatMap(_focusedDataPoints, _point =>
      _.map(
        ['posX', 'posY', 'xAxis'],
        prop => `${prop}:${_.get(_point, prop)}`,
      ).join(','),
    ),
    ';',
  );
