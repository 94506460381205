import StackChartUtils from '../StackChartUtils';
import {
  compose,
  onlyUpdateForKeys,
  pure,
  withPropsOnChange,
} from 'react-recompose';
import { BaseCartesianChart } from '../base-cartesian-chart';
import { connect } from 'react-redux';
import FunnelPlot from './plot';
import zipObject from 'lodash/zipObject';
import snakeCase from 'lodash/snakeCase';
import mapKeys from 'lodash/mapKeys';
import assign from 'lodash/assign';
import { isEmpty } from 'lodash/lang';
import { Viz } from '../../VizUtil';
import _ from 'lodash';
import {
  DATA_FORMATTER,
  NULL_DISPLAY,
  NULL_TOKEN,
} from '../../../common/Constants';
import NoDataIfHOC from '../NoDataIfHOC';
import DiscoverActions from '../../../common/redux/actions/DiscoverActions';

const AVG_DAYS_IN_STAGE_KEY = 'Avg. Days in Stage';

class FunnelChart extends BaseCartesianChart {
  constructor(props) {
    super(props, [{ shelf: 'VALUES', Plot: FunnelPlot, dataId: 'stackData' }]);
  }
}

/**
 * Funnel viz can optionally have avg days in stage data. if it is available in the query, inject it into the data object
 * used for rendering the Funnel
 * @param stackData - Data object expected by the Stacks component
 * @param queryResults - results we need to convert to something the Funnel can use
 */
const supplementWithAvgDaysInStage = (stackDataOrig, queryResults, viz) => {
  let stackData = _.cloneDeep(stackDataOrig);
  const customToggles = Viz.getCustomFormatTogglesFromViz(viz);
  const togglesWeCareAbout = [
    'avgDaysInStage',
    'avgConversionDays',
    'conversionRate',
  ];
  const toggles = customToggles
    .filter(ct => _.includes(togglesWeCareAbout, ct.key))
    .filter(ct => ct.on)
    .map(ct => ct.key);
  if (_.startsWith(toggles, 'avgDaysInStage')) {
    toggles.splice(1, 0, 'stageRecordCount');
  }

  const { results } = queryResults.executeQuery;
  const stacks = _.get(viz, 'layout.STACK', []).map(s => s.name);
  const dataKeys = ['Name', 'Ordinal', ...stacks, 'Stack'].concat(toggles);
  _.pull(dataKeys, 'center');
  const totalStacks = stackData.reduce((total, stack) => {
    total += stack.VALUES.length;
    return total;
  }, 0);

  const isColorStack = viz?.layout?.STACK?.length > 0;

  // set defaults
  stackData = _.map(stackData, stack => ({
    ...stack,
    TOTAL_RECORDS: 0,
    IS_COLOR_STACK: isColorStack,
  }));

  if (!isEmpty(toggles) && totalStacks === results.length) {
    // assuming largest number of records is the total
    let totalRecords = 0;
    stackData.forEach(stack => {
      // get the first occurrence of the stage in the results
      const stageResults = results.filter(row => row[0] === stack.XAXIS);
      const resultObj = mapKeys(
        zipObject(dataKeys, stageResults[0]),
        (val, key) => {
          return snakeCase(key).toUpperCase();
        },
      );
      assign(stack, resultObj);
      const avgDays = resultObj.AVG_DAYS_IN_STAGE;
      if (
        !_.isNil(avgDays) &&
        avgDays !== NULL_TOKEN &&
        avgDays !== NULL_DISPLAY
      ) {
        stack.VALUES.forEach(v => {
          v.tooltipInfo[AVG_DAYS_IN_STAGE_KEY] = avgDays;
        });
        stack.fullStackTooltipInfo[AVG_DAYS_IN_STAGE_KEY] = avgDays;
      }
      totalRecords = _.max([
        StackChartUtils.getStackTotals(stack.VALUES)?.positive,
        totalRecords,
      ]);
    });

    stackData = _.map(stackData, stack => ({
      ...stack,
      TOTAL_RECORDS: totalRecords,
      IS_COLOR_STACK: isColorStack,
    }));
  }
  return stackData;
};

const blendSecondaryData = (
  stackData,
  secondaryResults,
  viz,
  i18nPrefs = {},
) => {
  const columnNames = secondaryResults.executeQuery.columnInfo.map(
    ci => ci.attributeName,
  );
  const stageFieldName = _.get(viz, 'layout.XAXIS', [
    { name: columnNames[0] },
  ]).map(x => x.name)[0];
  const stackFieldNames = _.get(viz, 'layout.STACK', []).map(s => s.name);

  if (!_.isEmpty(stageFieldName) && !_.isEmpty(stackFieldNames)) {
    secondaryResults.executeQuery.results.forEach(row => {
      const data = zipObject(columnNames, row);
      // find the corresponding funnel stage
      const funnelStage = stackData.find(
        sd => sd.XAXIS === data[stageFieldName],
      );
      if (!_.isNil(funnelStage)) {
        // find the slice of the funnel
        const funnelValue = funnelStage.VALUES.find(v => {
          const numberOfMatchingStackFields = stackFieldNames.reduce(
            (matches, name) => {
              const isMatch = v.drillContext.attributes.find(attr => {
                return (
                  attr.attribute.name === name && attr.value === data[name]
                );
              });
              matches += _.isNil(isMatch) ? 0 : 1;
              return matches;
            },
            0,
          );
          return numberOfMatchingStackFields === stackFieldNames.length;
        });
        if (!_.isNil(funnelValue)) {
          // change the stack data inline here, add the sum and count of days in stage to the slice of the stack it goes with
          funnelValue.daysInStageSum = data.funnel_DaysInStage_Sum;
          funnelValue.daysInStageCount = data.funnel_DaysInStage_Count;

          // override the tooltip data for avg. days in stage for this slice
          const avg = funnelValue.daysInStageSum / funnelValue.daysInStageCount;
          if (!_.isNaN(avg)) {
            funnelValue.tooltipInfo[
              AVG_DAYS_IN_STAGE_KEY
            ] = DATA_FORMATTER.NUMBER.format(avg, i18nPrefs);
          }

          funnelValue.cycleTimeSum = data.funnel_CycleTime_Sum;
          funnelValue.cycleTimeCount = data.funnel_CycleTime_Count;
        }
      }
    });
  }
};
export default compose(
  pure,
  connect(
    BaseCartesianChart.mapStateToProps,
    BaseCartesianChart.mapDispatchToProps,
  ),
  connect(_.constant({}), (dispatch, ownProps) => {
    return {
      setFunnelStageVisibilityOptions: stageOptions => {
        dispatch(
          DiscoverActions.setFunnelStageVisibilityOptions(
            ownProps.vizId,
            stageOptions,
          ),
        );
      },
    };
  }),
  withPropsOnChange(['queryResults', 'i18nPrefs'], props => {
    const customFormatToggles = Viz.getCustomFormatTogglesFromViz(props.viz);

    // define map for value attribute...
    const valueMapping = props.viz.layout.VALUES.reduce((mapping, v) => {
      mapping[`window_${v.name}`] = v.name;
      return mapping;
    }, {});

    const stacks = _.get(props.viz, 'layout.STACK', []);
    const sortIndexes = _.range(1, 2 + stacks.length);

    // We need to sort the data before it goes into the transform since it depends on them being in the correct order.
    // We also need to be sure to sort on the ColorStack fields secondarily to get the colors to line up in the funnel.
    const sortedResults = _.orderBy(
      props.queryResults?.executeQuery?.results ?? [],
      sortIndexes,
    );
    const qResults = _.cloneDeep(props.queryResults);
    qResults.executeQuery.results = sortedResults;

    let stackData = StackChartUtils.transformResult(
      qResults,
      props.viz,
      customFormatToggles,
      'VALUES',
      false,
      valueMapping,
      props.i18nPrefs,
    );

    stackData = supplementWithAvgDaysInStage(
      stackData,
      props.queryResults,
      props.viz,
    );
    if (!_.isEmpty(props.secondaryQueryResults)) {
      // Add in the secondary data. it contains the days in stage sum and count for each slice of the funnel stack
      blendSecondaryData(
        stackData,
        props.secondaryQueryResults,
        props.viz,
        props.i18nPrefs,
      );
    }
    const legendData = StackChartUtils.getUniqueStackNames({
      queryResults: qResults,
      layout: props.viz.layout,
    });

    const funnelStageVisibilityOptions = JSON.parse(
      _.get(props.viz?.options, 'funnelStageVisibility', '[]'),
    );
    const stageNames = _.uniq(_.map(qResults.executeQuery.results, _.head));
    const stageOptions = _.map(stageNames, label => {
      const key = _.camelCase(label);
      // convert undefined stages to boolean
      const isVisible =
        _.find(funnelStageVisibilityOptions, { key })?.isVisible ?? true;

      return {
        key,
        label,
        isVisible,
      };
    });

    if (
      !_.isEqual(
        _.map(stageOptions, 'key'),
        _.map(funnelStageVisibilityOptions, 'key'),
      )
    ) {
      props.setFunnelStageVisibilityOptions(stageOptions);
    }

    return { stackData, legendData };
  }),
  onlyUpdateForKeys(['stackData', 'height', 'width', 'id']),
  NoDataIfHOC(props => props.stackData.length === 0),
)(FunnelChart);
