import _ from 'lodash';
import { Viz } from '../VizUtil';
import ChartUtils from './ChartUtils';
import { NULL_DISPLAY } from '../../common/Constants';

const BarChartUtils = {
  findShelfByFieldName: ChartUtils.findShelfByFieldName,
  getValueField: ChartUtils.getValueField,
  getX: ChartUtils.getX,
  getX0: ChartUtils.getX0,
  getY: ChartUtils.getY,

  /**
   *
   * @param queryResults
   * @param viz a visualization
   * @param valueShelfName Shelf where the bar values attributes are located
   * @param i18nPrefs user i18n preferences
   */
  transformResult: (
    queryResults,
    viz,
    valueShelfName = 'VALUES',
    i18nPrefs = {},
  ) => {
    const layout = _.omit(viz?.layout ?? {}, 'SLICER');
    const dataFormatters = Viz.getDataFormatters(viz);
    const customDataFormatters = Viz.getDataCustomFormatters(viz);
    const vizFilters = Viz.getFiltersFromViz(viz);
    const vizCalcs = Viz.getCalcsFromViz(viz);

    const fieldLookup = columnInfo => {
      const shelf = BarChartUtils.findShelfByFieldName(
        columnInfo.attributeName,
        layout,
      );
      return _.find(layout[shelf], { name: columnInfo.attributeName });
    };

    const barData = queryResults.executeQuery.results.reduce(
      (accum, current) => {
        // turn each 'row' from the query result into a more bar-chart friendly object
        const querySort = queryResults.executeQuery.querySort || {};
        const data = {
          XAXIS: {},
          COLUMNS: {},
          VALUES: {},
          formatters: [],
        };
        const tooltipInfo = {};

        const drillContext = {
          filters: vizFilters,
          calcs: vizCalcs,
          attributes: [],
          metrics: [...layout[valueShelfName]],
          linkToReport: JSON.parse(_.get(viz, 'options.linkToReport', '{}')),
        };

        data.drillContext = drillContext;

        queryResults.executeQuery.columnInfo.forEach((columnInfo, idx) => {
          const shelf = BarChartUtils.findShelfByFieldName(
            columnInfo.attributeName,
            layout,
          );
          // Check to see if we care about this type
          const keys = Object.keys(data);

          if (_.includes(keys, shelf)) {
            const field = _.find(layout[shelf], {
              name: columnInfo.attributeName,
            });
            if (!_.isNil(field)) {
              // this is set, and then
              const formattedVal = ChartUtils.formatValue(
                dataFormatters,
                customDataFormatters,
                i18nPrefs,
                field.name,
                current[idx],
                shelf === 'XAXIS',
              );
              const sortValue = querySort[field.name]
                ? current[querySort[field.name].index]
                : formattedVal;
              data[shelf][columnInfo.attributeName] = {
                value: formattedVal,
                type: field.attributeType,
                sort: sortValue,
              };
              tooltipInfo[columnInfo.attributeName] = formattedVal;
              if (shelf !== valueShelfName) {
                drillContext.attributes.push({
                  attribute: fieldLookup(columnInfo),
                  value: ChartUtils.valueLookup(
                    field,
                    current,
                    queryResults.executeQuery.columnInfo,
                    idx,
                  ),
                });
              }
            }
          }
          if (shelf === valueShelfName) {
            data.VALUES[columnInfo.attributeName] =
              current[idx] === NULL_DISPLAY ? 0 : current[idx];
            const valueField = BarChartUtils.getValueField(
              columnInfo.attributeName,
              layout,
              valueShelfName,
            );
            data.COLUMNS[columnInfo.attributeName] = {
              value: valueField.name,
              type: valueField.attributeType,
            };
            data.formatters.push(dataFormatters[valueField.name]);
            tooltipInfo[valueField.name] = ChartUtils.formatValue(
              dataFormatters,
              customDataFormatters,
              i18nPrefs,
              valueField.name,
              current[idx],
            );
          }
          data.tooltipInfo = tooltipInfo;
        });

        const valueCount = Object.keys(data.VALUES).length;
        if (valueCount > 1) {
          // we have multiple values, create an object for each one since each will represent a bar in the chart
          const keys = Object.keys(data.VALUES);

          // Loop thru creating an entry for each measure by cloning and removing the other measure keys
          const multiValues = [];
          keys.reverse().forEach(key => {
            const d = _.cloneDeep(data);
            keys.forEach(otherKey => {
              if (otherKey !== key) {
                // delete other keys
                const fieldName = d.COLUMNS[otherKey].value;
                delete d.tooltipInfo[fieldName];
                delete d.VALUES[otherKey];
                delete d.COLUMNS[otherKey];
              }
            });
            // since we are going in reverse order, add it to the beginning of the array so they end up in the correct order
            multiValues.unshift(d);
          });
          accum = [...accum, ...multiValues];
        } else if (valueCount === 1) {
          // remove the value field from the column
          const d = _.cloneDeep(data);
          // delete d.COLUMNS[Object.keys(data.VALUES)[0]]
          accum.push(d);
        } else {
          // create a single row for each value
          accum.push(data);
        }
        return accum;
      },
      [],
    );

    return barData;
  },

  getY0: d => {
    return d.VALUES[Object.keys(d.VALUES)[1]];
  },
};

export default BarChartUtils;
