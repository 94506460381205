import { useContext, useRef, useState } from 'react';
import { min, head, map, groupBy, flatten, every, noop } from 'lodash';
import { CorbotoLoading } from '../../../common/loaders';
import {
  PipelineChangesSvg,
  IPipelineChangesColumnData,
  IPipelineChangesDataAnnotated,
  MetricAggregateType,
  StagesWithMetricAggregateType,
  useMetricsWithStageAggregation,
  useAnnotatedData,
  useScaleFeatures,
  useD3DataBuilder,
  NoDataHoc,
  useRenderer,
  useMouseEvents,
  useDrillLinking,
  DEFAULT_PADDING_Y,
  useFocusedData,
  useLegend,
} from '../pipeline-changes';
import { useChartTooltip } from '../chart-tooltip/chart-tooltip.hook';
import { ChartTooltipStyled as ChartTooltip } from '../chart-tooltip/chart-tooltip.styles';
import {
  useStagedFields,
  useFindCalc,
  useBarColor,
  useStartEndDate,
  useWaterfallCalcs,
  useYAxisMetrics,
  useOnHover,
} from './waterfall.hook';
import { DATA_FORMATTER } from '../../../common/Constants';
import { IWaterfallProps } from './waterfall.interface';
import { ChartContext } from '../../viz-chart/chart.hook';

export const WaterfallChart = ({
  vizId,
  queryResults,
  vizLoading: loading = false,
  queryError = false,
}: IWaterfallProps) => {
  const { height: chartHeight, width: chartWidth } = useContext(ChartContext);

  const svgRef = useRef(null);

  const { toShelves, additionalCalcs, additionalMetrics } = useWaterfallCalcs(
    vizId,
  );

  const {
    stageOrder,
    upsideStages,
    downsideStages,
    metricFields,
  } = useStagedFields(vizId);

  // calculate available chart dimensions
  const chartXPadding = min([chartWidth / 10, 75]);
  const [chartYPadding, setYPadding] = useState(DEFAULT_PADDING_Y);

  const { executeQuery: { columnInfo = [], results = [] } = {} } = queryResults;

  const metricsWithStageAggregation: MetricAggregateType[][] = useMetricsWithStageAggregation(
    stageOrder,
    metricFields,
    columnInfo,
    results,
  );

  const yAxisMetrics = useYAxisMetrics({ vizId, metricsWithStageAggregation });

  const stageNames = map(yAxisMetrics, 'stage');
  const stageLabels = map(yAxisMetrics, 'stageLabel');

  const metricAggregationsByStage: StagesWithMetricAggregateType = groupBy(
    flatten(metricsWithStageAggregation),
    'stage',
  );

  /**
   * build the data for d3 to consume
   * - calculates absolute and relative amounts for the eventual size/position of bars
   * - label
   * - closed won flag (special color flag)
   * - formatter (for eventual render)
   */
  const pipelineChangesData: IPipelineChangesDataAnnotated[] = useAnnotatedData(
    queryError,
    upsideStages,
    yAxisMetrics,
  );

  // scale-related functions/variables from d3
  const {
    xScale,
    xScaleDomainSelector,
    yScale,
    yTickValues,
    barWidth,
    bandWidth: maximumBarWidth,
  } = useScaleFeatures({
    vizId,
    stageLabels,
    chartXPadding,
    chartYPadding,
    pipelineChangesData,
  });

  const { isDataFocused, setFocusedVizData, buildFocusObject } = useFocusedData(
    {
      vizId,
    },
  );

  const { getBarColor } = useBarColor({
    vizId,
  });

  const getFieldByStageName = useFindCalc(vizId);

  // data that will be provided to each stage via d3
  const d3Data: IPipelineChangesColumnData[] = useD3DataBuilder({
    vizId,
    xScale,
    xScaleDomainSelector,
    yScale,
    pipelineChangesData,
    metricAggregationsByStage,
    barWidth,
    maximumBarWidth,
    downsideStages,
    isDataFocused,
    getBarColor,
    showPositiveLabels: true,
    getFieldByStageName,
    additionalCalcs,
    additionalMetrics,
    toShelves,
  });

  useLegend({
    vizId,
    d3Data,
    buildFocusObject,
  });

  const { drillLinkIntoStage } = useDrillLinking({
    vizId,
    getFieldByStageName,
    additionalCalcs,
    additionalMetrics,
    toShelves,
    setFocusedVizData,
  });

  // TooltipProvider can eventually live in chart.component.tsx
  const {
    provider: TooltipProvider,
    providerValue,
    setTooltipData = noop,
  } = useChartTooltip({
    vizId,
    svgRef,
  });

  const onHover = useOnHover({ vizId, setTooltipData });

  const {
    onBarMouseEnter,
    onBarMouseMove,
    onBarMouseLeave,
    onBarClick,
  } = useMouseEvents({
    svgRef,
    onHover,
    drillLink: drillLinkIntoStage,
  });

  const { start, end } = useStartEndDate(vizId);

  useRenderer({
    vizId,
    svgRef,
    d3Data,
    xScale,
    yScale,
    yTickValues,
    yTickFormatter:
      head(yAxisMetrics)?.metricValueFormatter ?? DATA_FORMATTER.WHOLE_NUMBER,
    stageLabels,
    onBarMouseEnter,
    onBarMouseMove,
    onBarMouseLeave,
    onBarClick,
    chartYPadding,
    chartXPadding,
    setYPadding,
    upsideStages,
    xScaleDomainSelector,
    secondaryXAxisLabels: [start, end],
  });

  const hasNoData =
    !!queryError ||
    results?.length === 0 ||
    stageNames?.length === 0 ||
    ((metricAggregationsByStage as unknown) as any[])?.length === 0 ||
    every(pipelineChangesData, { value: 0 });

  return (
    <>
      {loading && <CorbotoLoading />}
      {!loading && (
        <NoDataHoc condition={hasNoData}>
          <TooltipProvider value={providerValue}>
            <PipelineChangesSvg
              key={'waterfall-chart'}
              id={'vizArea'}
              ref={svgRef}
              width={chartWidth}
              height={chartHeight}
            >
              <ChartTooltip />
            </PipelineChangesSvg>
          </TooltipProvider>
        </NoDataHoc>
      )}
    </>
  );
};
