import _, { isEmpty, isNil, map } from 'lodash';
import { LineChartUtils } from '../../../../discovery/charts/LineChartUtils/LineChartUtils';
import { IDrillContext } from '../../../../discovery/viz-redirect';

const setIdentifier = (_attributeName, _prefilterOperandValue) =>
  `${_attributeName}:${_.join(_.map(_prefilterOperandValue, _.toString), ',')}`;

export const focusPointsFromDrillContext = ({
  drillFocusEnabled,
  drillContext: drillContext,
  data,
  getXPos,
}: {
  drillFocusEnabled: boolean;
  drillContext?: IDrillContext;
  data?: any;
  getXPos?: any;
}) => {
  const drillFocusPoints = [];
  if (!drillFocusEnabled || isNil(drillFocusEnabled) || isNil(data)) {
    return drillFocusPoints;
  }

  // key value from drillContext?.attributes
  const lookupSet = new Set();
  _.forEach(
    drillContext?.attributes ?? [],
    ({
      attribute: { name: _attributeName },
      value: _prefilterOperandValue,
    }) => {
      lookupSet.add(setIdentifier(_attributeName, _prefilterOperandValue));
    },
  );

  const drillAttrLookup = new Set<string>(
    map(drillContext.attributes, ({ attribute: { name }, value }) =>
      setIdentifier(name, value),
    ).filter(Boolean),
  );

  (data?.LINES ?? []).forEach(_linePoints => {
    const { linesData = [] } = _linePoints?.pathInfo;

    const lineDataLookup = new Set<string>(
      map(linesData, ({ attributeName, displayValue }) =>
        setIdentifier(
          attributeName,
          Array.isArray(displayValue) ? displayValue : [displayValue],
        ),
      ).filter(Boolean),
    );

    const hasLineDrillAttributes =
      (drillAttrLookup as any).intersection(lineDataLookup).size > 0;

    if (!isEmpty(linesData) && !hasLineDrillAttributes) {
      // multi-line chart, but this line is not focused
      return;
    }

    _linePoints.forEach(_point => {
      const xAxisPrefilters = LineChartUtils.includeXAxisDetailInfo(
        data,
        _point,
        'XAXIS',
      );

      if (
        _.every(
          xAxisPrefilters,
          ({ attribute: { name: _axisAttributeName }, value: axisVal }) =>
            lookupSet.has(setIdentifier(_axisAttributeName, axisVal)),
        )
      ) {
        drillFocusPoints.push({
          ..._point,
          xAxis: _point.x,
          pathInfo: _linePoints.pathInfo,
          posX: getXPos(_point),
        });
      }
    });
  });

  return drillFocusPoints;
};

export const getPlotXPos = ({ point, xScale, getX, offsetX, context }) => {
  const { leftPct = 1, offscreenWidth = 0 } = context ?? {};
  const widthScrollOffset = leftPct * offscreenWidth;

  const xVal = xScale(getX(point));

  return (
    (_.isNaN(xVal) || _.isNil(xVal) ? 0 : xVal + offsetX) - widthScrollOffset
  );
};
