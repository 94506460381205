import { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { whiteStripesId } from '../../../common';

const SVGComponent = forwardRef<SVGSVGElement>(
  ({ children, ...props }: { children: ReactNode }, ref) => {
    return (
      <svg {...props} ref={ref}>
        <defs>
          <pattern
            id={whiteStripesId}
            width='10'
            height='10'
            patternUnits='userSpaceOnUse'
            patternTransform='rotate(45)'
          >
            <rect width='5' height='10' fill='rgba(0, 0, 0, 0.25)' />
          </pattern>
        </defs>
        {children}
      </svg>
    );
  },
);

export const PipelineChangesSvg = styled(SVGComponent)(
  ({
    theme: {
      colors: { Gray40, Gray70 },
    },
  }: any) => css`
    .gridlines line {
      stroke: ${Gray40};
    }
    line.connector {
      stroke: ${Gray70};
    }
  `,
);
